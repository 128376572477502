.container-shell {
  display: grid;
  grid-template-columns: auto minmax(500px, 4fr);
  justify-content: stretch;
  height: 100%;
}

.container-shell-children {
  display: flex;
  grid-column-start: 2;
  width: 100%;
}

.container-shell-sidebar {
  grid-column-start: 1;
  height: 100vh;
  position: sticky;
  top: 0;
}
